import { API_URL } from '../config';

export const fetcher = async (url: string, method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD', body?: any) : Promise<{status: number, result?: any}> => {


  let bodyHeader = {};

  body && (bodyHeader = {
    body: JSON.stringify(body),
  });

  const result = await fetch(`${API_URL}${url}`, {
    method: method ? method : 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
     // 'x-log-token' : String(token),



      mode: 'cors',
    },

    ...bodyHeader,

  });
  let jsonTest = null;
  try {
    jsonTest = await result.json();
  } catch (e) {

  }



  if(([403,404,204,401].indexOf(result.status)) !== -1) {
    return {
      status: result.status,
      result: jsonTest,
    }
  }

/*
  if(result.status === 403 || result.status === 404) {

  }
*/

  return {
    result: jsonTest,
    status: result.status,
  };


  }




